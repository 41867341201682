<template>
  <div class="text-charcoal-grey bg-pure-white">
    <ViewHeadSection :description-text="$t('data_privacy.top_left_title_text')">
      <p class="w-full flex-no-shrink font-sans-bold text-xl text-faded-red">
        {{ $t('data_privacy.top_left_title_text') }}
      </p>
    </ViewHeadSection>
    <div class="bg-pure-white mb-50">
      <div class="pl-39 pt-32 pr-39">
        <p
            v-html="informations[$i18n.locale]"
            class="text-style-7 whitespace-pre-line leading-22"
        >
        </p>
      </div>
    </div>
    <div class="bg-pure-white h-80"></div>
  </div>
</template>


<script>
import IconArrow from '@/components/icon/IconArrow';
import ViewHeadSection from '@/components/view/ViewHeadSection';

const cookiePolicy = '<a class="text-faded-red no-underline" href="#cookie-policy">' +
  'Cookie Policy</a>';

const companiesList = '<a class="text-faded-red no-underline" target="_blank" href="https://www.swisskrono.com/de-de/ueber-uns/swiss-krono-group/standorte/#/">' +
  'hier</a>';

const companiesListEn = '<a class="text-faded-red no-underline" target="_blank" href="https://www.swisskrono.com/de-en/about-us/swiss-krono-group/locations/#/">' +
  'here</a>';

const timberplannerLink = {
  short_link: '<a class="text-faded-red no-underline" target="_blank" href="https://timberplanner.com">' +
    'www.timberplanner.com</a>',
  long_link: '<a class="text-faded-red no-underline" target="_blank" href="https://timberplanner.com">' +
    'https://timberplanner.com</a>',
};
const swisskronoLink = '<a class="text-faded-red no-underline" target="_blank" href="https://www.swisskrono.com/de-de/">' +
  'https://www.swisskrono.com/de-de/</a>';

const swisskronoLinkEn = '<a class="text-faded-red no-underline" target="_blank" href="https://www.swisskrono.com/de-en/">' +
  'https://www.swisskrono.com/de-en/</a>';

const responsibleMail = '<a class="text-faded-red no-underline" href="mailto:datenschutzbeauftragter@swisskrono.com">' +
  'datenschutzbeauftragter@swisskrono.com</a>';

const googlePrivacyLink = '<a class="text-faded-red no-underline" target="_blank" href="https://firebase.google.com/support/privacy">' +
  'https://firebase.google.com/support/privacy</a>';

const browserPluginLink = '<a class="text-faded-red no-underline" target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=de">' +
  'https://tools.google.com/dlpage/gaoptout?hl=de</a>';
  
const browserPluginLinkEn = '<a class="text-faded-red no-underline" target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=en">' +
  'https://tools.google.com/dlpage/gaoptout?hl=en</a>';

const googleAnalyticsLink = '<a class="text-faded-red no-underline" target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=de">' +
  'https://support.google.com/analytics/answer/6004245?hl=de</a>';

const googleAnalyticsLinkEn = '<a class="text-faded-red no-underline" target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=en">' +
  'https://support.google.com/analytics/answer/6004245?hl=en</a>';

const eurlexEuropaLink = '<a class="text-faded-red no-underline" target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32010D0087">' +
  'https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32010D0087</a>';

const eurlexEuropaLinkEn = '<a class="text-faded-red no-underline" target="_blank" href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32010D0087">' +
  'https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32010D0087</a>';

const dataProtectionMail = '<a class="text-faded-red no-underline" href="mailto:dataprotection@swisskrono.com">' +
  'dataprotection@swisskrono.com</a>';

export default {
  components: {
    IconArrow,
    ViewHeadSection,
  },
  data() {
    return {
      informations: {
        de: `Wenn Sie ${timberplannerLink.short_link} benutzen, werden verschiedene personenbezogene Daten erhoben.

Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.

Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

WER FÜR DEN INHALT DIESER WEBSITE UND DIE DARAUF ABLAUFENDE ERHEBUNG IHRER PERSONENBEZOGENEN DATEN VERANTWORTLICH IST

Wir, SWISS KRONO Tec AG, Museggstrasse 14, 6004 Luzern, Schweiz, sind verantwortliche Stelle für sämtliche personenbezogenen Daten, die Sie uns im Rahmen der Nutzung unserer Website ${timberplannerLink.long_link} zur Verfügung stellen. Unsere Website kann Links zu anderen Websites enthalten. Diese Websites werden von uns nicht betrieben und wir haben daher keinen Einfluss auf deren Inhalte, sowie deren Umgang mit dem Datenschutz. Sofern Sie via einen solchen Link andere SWISS KRONO Websites besuchen, ist verantwortliche Stelle der jeweilige im Impressum genannte Betreiber der Website. Lesen Sie in diesem Fall die von diesen Websites angebotenen Datenschutzerklärungen sogfältig durch.

Im Übrigen ist die jeweilige SWISS KRONO Gesellschaft, mit der Sie in Geschäftsbeziehung stehen verantwortlich für Ihre personenbezogenen Daten, bzw. die ausdrücklich in einer Kommunikation mit Ihnen genannte SWISS KRONO Gesellschaft. Eine Liste der SWISS KRONO Gesellschaften mit Adressen finden Sie ${companiesList}.

KATEGORIEN DER VON UNS ERHOBENEN PERSONENBEZOGENEN DATEN

Wir erheben und verarbeiten insbesondere folgende Kategorien von personenbezogenen Daten:

Kontaktinformationen wie vollständiger Name, E-Mail-Adresse, persönliches Passwort, die Identifikationsnummer Ihres Mobiltelefons und die IP-Adresse Ihres Computers, wenn Sie sich auf der Website ${timberplannerLink.long_link} unter Angabe personenbezogener Daten registrieren.

Mit den verarbeiteten Daten erstellen wir für Sie ein individualisiertes Nutzerkonto, mit dem Sie bestimmte Inhalte und Leistungen auf unserer Webseite nutzen können. Ihre E-Mail-Adresse verarbeiten wir, damit wir Ihnen neue Zugangsdaten zusenden können, falls Sie diese einmal vergessen sollten.

Informationen über Ihre Präferenzen und Interessen, die wir aus den in unserer ${cookiePolicy} genannten Web-Tracking- oder Analyse-Technologien erhalten, insbesondere bei Nutzung unserer Internetseite und Inhalten, die wir Ihnen zum Download verfügbar machen (z.B. bei der Registrierung zum Download von Software, eBooks, Whitepapers), oder anderen Online Dienstleistungen, die wir Ihnen anbieten. So erheben wir etwa Informationen darüber, welche Inhalte Sie von unserer Website herunterladen und welche Inhalte Sie in welcher Weise angeklickt oder angesehen haben.

FÜR WELCHE ZWECKE WIR IHRE PERSONENBEZOGENEN DATEN VERWENDEN

Wir werden Ihre personenbezogenen Daten ausschliesslich für die folgenden Zwecke verwenden („Zulässige Zwecke“):

Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.

Verwaltung der Login-Daten zu Ihrem individualisierten Nutzerkonto, mit dem Sie bestimmte Inhalte und Leistungen auf unserer Webseite nutzen können. Hinterlegung Ihrer E-Mail-Adresse, damit wir Ihnen neue Zugangsdaten zusenden können, falls Sie diese einmal vergessen sollten.

Aufrechterhaltung und Schutz der Sicherheit unserer Produkte, Dienstleistungen und Webseiten oder anderer Systeme, Verhinderung und Aufdeckung von Sicherheitsrisiken, Betrugsfällen oder anderen kriminellen oder rechtswidrigen Handlungen.

Allgemeine Compliance Zwecke, insbesondere zur Gewährleistung der Einhaltung unserer gesetzlichen und regulatorischen Verpflichtungen und Anforderungen, wie beispielsweise Compliance-Screenings oder Aufzeichnungspflichten (z.B. aufgrund von Wettbewerbsrecht, Ausfuhrrecht, Handelssanktionen und Embargobestimmungen oder zur Prävention von Wirtschaftskriminalität oder Geldwäsche). Das kann folgende Massnahmen beinhalten: Abgleich Ihrer Kontakt- oder Identitätsdaten mit einschlägigen Sanktionslisten und Bestätigung Ihrer Identität im Falle einer möglichen Übereinstimmung, Aufzeichnung Ihrer Zusammenarbeit mit Dritten, soweit diese von kartellrechtlicher Relevanz sein könnte, Meldungen an oder Prüfung durch zuständige Aufsichts-, Strafverfolgungs- oder andere zuständige Behörden.

Die Rechtsgrundlagen für die Verarbeitung Ihrer personenbezogenen Daten sind in Artikel 6 DSGVO festgelegt. Je nachdem, für welchen der vorstehend beschriebenen zulässigen Zwecke wir Ihre personenbezogenen Daten verarbeiten, erfolgt die Verarbeitung entweder zur Erfüllung eines Vertrags oder einer anderen geschäftlichen Vereinbarung mit SWISS KRONO oder zur Einhaltung unserer gesetzlichen Verpflichtungen oder zur Wahrung der berechtigten Interessen von SWISS KRONO oder Dritten, stets unter der Voraussetzung, dass Ihre Interessen oder Grundrechte und Grundfreiheiten nicht überwiegen und einer Verarbeitung entgegenstehen. Darüber hinaus erfolgt die Verarbeitung ggf. auf Grundlage Ihrer ausdrücklichen Einwilligung soweit Sie uns diese erteilt haben.

WIE ERFASSEN WIR IHRE DATEN?

Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.

Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.

WELCHE RECHTE HABEN SIE BEZÜGLICH IHRER DATEN?

Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben auch das Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Sie haben ausserdem ein Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Im Falle datenschutzrechtlicher Verstösse steht dem Betroffenen ein Beschwerderecht an die zuständige Aufsichtsbehörde zu.

ANALYSE-TOOLS UND TOOLS VON DRITTANBIETERN

Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie im folgenden Teil der Datenschutzerklärung.

DATENSICHERHEIT

Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Anlegen einer Projekt-Liste oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.

<div id="cookie-policy" class="inline-block">Wenn</div> die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.

COOKIES

Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.

Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.

Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen sowie das automatische Löschen der Cookies beim Schliessen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.

Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.

SERVER-LOG-DATEIEN

Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt.

Dies sind:
•    Browsertyp und Browserversion
•    verwendetes Betriebssystem
•    Referrer URL
•    Hostname des zugreifenden Rechners
•    Uhrzeit der Serveranfrage
•    IP-Adresse

Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.

Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Massnahmen gestattet.

KONTAKTFORMULAR

Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Sie auf die Website ${swisskronoLink} weitergeleitet und müssen vor dem Versenden Ihrer Nachricht die Datenschutz-Erklärung des entsprechenden Verantwortlichen (SWISS KRONO Tex GmbH & Co KG) akzeptieren. Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten werden zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen beim Verantwortlichen gespeichert. Diese Daten werden nicht ohne Ihre Einwilligung weitergegeben.

Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschliesslich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an den Verantwortlichen (${responsibleMail}). Die Rechtmässigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.

Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben beim Verantwortlichen, bis Sie zu deren Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.

REGISTRIERUNG AUF DER WEBSITE

Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten verwalten wir mit Hilfe von Google Firebase. Wir verwenden diese Daten nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.

Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.

Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmässigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.

Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf unserer Website registriert sind und werden anschliessend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.

Mehr Informationen zum Umgang mit Nutzerdaten bei Google Firebase finden Sie in der Datenschutz-Erklärung von Google:

${googlePrivacyLink}

VERARBEITUNG VON DATEN (KUNDEN- UND VERTRAGSDATEN)

Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Massnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen.

Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.

GOOGLE ANALYTICS

Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.

Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.

Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.

IP Anonymisierung

Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Website-nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.

Browser Plugin

Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:

${browserPluginLink}

Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:

${googleAnalyticsLink}

Auftragsdaten

SWISS KRONO hat mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzt die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.

Demografische Merkmale bei Google Analytics

Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google- Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.

WO PERSONENBEZOGENE DATEN VERARBEITET WERDEN

Im Rahmen des kostenlosen zur Verfügung Stellens der Funktionen unserer Website ${timberplannerLink.long_link} ist es möglich, dass wir Ihre personenbezogenen Daten auch an Empfänger in Ländern ausserhalb des Europäischen Wirtschaftsraums („Drittländer“) weiterleiten, in denen nicht das gleiche Datenschutzniveau besteht, wie in Ihrem Heimatland. Soweit wir dies tun, beachten wir die anwendbaren Datenschutzanforderungen und ergreifen angemessene Sicherheitsmassnahmen, um sicherzugehen, dass Ihre personenbezogenen Daten geschützt und sicher sind, insbesondere durch die Vereinbarung von EU-Standardvertragsklauseln, die Sie hier abrufen können:

${eurlexEuropaLink}

Wenn Sie weitere Informationen über diese Sicherheitsmassnahmen erhalten möchten, können Sie uns jederzeit über die untenstehenden Kontaktdaten kontaktieren.

WIE WIR IHRE PERSONENBEZOGENEN DATEN SCHÜTZEN

Zum Schutz Ihrer personenbezogenen Daten ergreifen wir physische, elektronische und prozesstechnische Sicherheitsmassnahmen, die dem derzeitigen Stand der Technik und den gesetzlichen Datenschutzanforderungen entsprechen. Diese Schutzmassnahmen umfassen die Umsetzung bestimmter Technologien und Verfahren zum Schutz ihrer Privatsphäre, wie beispielsweise sichere Server, Firewalls sowie SSL-Verschlüsselung. Wir handeln jederzeit im Einklang mit den anwendbaren Gesetzen und Vorschriften in Bezug auf die Vertraulichkeit und Sicherheit von personenbezogenen Daten.

AN WEN WIR IHRE PERSONENBEZOGENE DATEN WEITERGEBEN

Wir geben Ihre personenbezogenen Daten ggf. wie folgt weiter:

an unsere verbundenen Unternehmen innerhalb der SWISS KRONO Gruppe weltweit, wenn und soweit dies für die oben beschriebenen zulässigen Zwecke erforderlich und gesetzlich zulässig ist. In solchen Fällen werden diese Unternehmen die personenbezogenen Daten nur für dieselben zulässigen Zwecke und unter denselben Bedingungen wie vorstehend verwenden.

an Dienstleister (sogenannte Auftragsverarbeiter) innerhalb oder ausserhalb der SWISS KRONO-Gruppe im In- oder Ausland (z.B. Shared Service Center oder Cloud-Anbieter), die von uns beauftragt wurden, in unserem Namen und ausschliesslich nach unseren Anweisungen personenbezogene Daten für die zulässigen Zwecke zu verarbeiten. In diesem Fall behalten wir die Kontrolle über und Verantwortung für Ihre personenbezogenen Daten und werden geeignete, gemäss dem anwendbarem Recht erforderliche Schutzmassnahmen ergreifen, um die Integrität und Sicherheit Ihrer personenbezogenen Daten bei Beauftragung solcher Dienstleister zu gewährleisten.

an Gerichte, Strafverfolgungsbehörden oder andere zuständige Behörden oder Anwälte soweit rechtlich zulässig und erforderlich, um einer rechtlichen Verpflichtung nachzukommen oder zur Feststellung, Geltendmachung oder Verteidigung von Rechtsansprüchen.

an Kreditauskunfteien und anderen Gesellschaften im Rahmen von Kreditentscheidungen, zur Vermeidung von Betrugsfällen und für den Forderungseinzug.

Andernfalls werden wir Ihre personenbezogenen Daten nur weitergeben, wenn Sie uns dazu anweisen oder Ihre Einwilligung erteilen, wenn wir rechtlich oder aufgrund einer gerichtlichen oder behördlichen Anordnung dazu verpflichtet sind oder wenn wir den Verdacht hegen, dass betrügerische oder kriminelle Handlungen vorliegen.

WIE LANGE SPEICHERN WIR IHRE PERSONENBEZOGENEN DATEN

Wir werden Ihre personenbezogenen Daten so lange speichern, wie es für die Erbringung der beauftragten Dienstleistungen oder Produkte oder verlangten Informationen und für die Durchführung und Verwaltung unserer Geschäftsbeziehung mit Ihnen erforderlich ist. Wenn Sie uns aufgefordert haben, nicht mit Ihnen in Kontakt zu treten, werden wir diese Informationen so lange aufbewahren, wie zur Erfüllung dieser Aufforderung notwendig. Darüber hinaus sind wir rechtlich verpflichtet, bestimmte Arten von personenbezogenen Daten für gewisse Zeiträume aufzubewahren (z. B. aufgrund handelsrechtlicher oder steuerrechtlicher Aufbewahrungspflichten). Ihre personenbezogenen Daten werden unverzüglich gelöscht, wenn sie für diese Zwecke nicht mehr erforderlich sind.

VERPFLICHTUNG ZUR BEREITSTELLUNG PERSONENBEZOGENER DATEN

Generell überlassen Sie uns Ihre personenbezogenen Daten auf freiwilliger Basis. Grundsätzlich hat es keine nachteiligen Folgen für Sie, wenn Sie nicht einwilligen oder Ihre personenbezogenen Daten nicht zur Verfügung stellen. Es gibt jedoch Fälle, in denen wir ohne bestimmte Ihrer personenbezogenen Daten nicht tätig werden können, z. B. wenn diese personenbezogenen Daten erforderlich sind, um Ihre Bestellungen zu bearbeiten, Ihnen Zugang zu einem Online-Angebot oder Newsletter zu verschaffen oder eine gesetzlich erforderliche Compliance-Prüfung durchzuführen. In diesen Fällen können wir Ihre Anfrage/Ihren Wunsch ohne die massgeblichen personenbezogenen Daten leider nicht erfüllen.

WENN ICH UNTER 16 JAHRE ALT BIN

Wenn Sie unter 16 Jahre alt sind, benötigen Sie die Einwilligung Ihrer Eltern/Ihres Erziehungsberechtigten, bevor Sie uns Ihre personenbezogenen Daten zur Verfügung stellen. Personen unter 16 Jahren ist es nicht gestattet, uns ihre personenbezogenen Daten ohne eine solche Einwilligung zu übermitteln.

ÄNDERUNGEN DIESER INFORMATION

Diese datenschutzrechtliche Information wurde zuletzt im Juli 2019 geändert. Wir werden diese Information ggf. ändern oder ergänzen, wenn das aufgrund von Änderungen in der Art und Weise der Datenverarbeitung oder der rechtlichen Rahmenbedingungen notwendig wird. Bitte prüfen Sie daher von Zeit zu Zeit oder wenn Sie uns personenbezogene Daten zur Verfügung stellen, ob sich Änderungen ergeben haben. Änderungen der Datenschutzinformation gelten ab dem Datum, an dem sie auf unserer Webseite veröffentlicht wurden.

WIE SIE MIT UNS IN KONTAKT TRETEN KÖNNEN

Falls Sie Fragen haben oder Ihre Rechte geltend machen wollen, kontaktieren Sie uns bitte unter

SWISS KRONO Tec AG
Museggstrasse 14
6004 Luzern
Switzerland
E-Mail: ${dataProtectionMail}
`,
        en: `When you use  ${timberplannerLink.short_link}, various personal data will be collected.

Personal data is data with which you can be personally identified. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done.

We would like to indicate that data transmission on the Internet (e.g. communication by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible.

WHO IS RESPONSIBLE FOR THE CONTENT OF THIS WEBSITE AND THE COLLECTION OF YOUR PERSONAL DATA ON IT

We, SWISS KRONO Tec AG, Museggstrasse 14, 6004 Lucerne, Switzerland, are the responsible party for all personal data that you provide to us in relation of using our website ${timberplannerLink.long_link} Our website may contain links to other websites. These websites are not operated by us and we therefore have no influence on their content or their handling of data protection. If you visit other SWISS KRONO websites by such a link, the responsible entity is the respective website operator named in the imprint. In this case, please read the data protection declarations offered by these websites carefully.

Otherwise, the respective SWISS KRONO company in which you have a business relationship is responsible for your personal data, or the SWISS KRONO company explicit named in a communication with you. You can find a list of SWISS KRONO companies with addresses ${companiesListEn}.

CATEGORIES OF PERSONAL DATA WE COLLECT

In particular, we collect and process the following categories of personal data:

Contact information such as full name, e-mail address, personal password, the identification number of your mobile phone and the IP address of your computer if you register on the website ${timberplannerLink.long_link} by providing personal data.

With the processed data, we create an individualized user account for you, in which you can use certain content and services on our website. We process your e-mail address so that we can send you new access data if you should forget it.

Information about your preferences and interests that we obtain from the web tracking or analytics technologies referred to in our ${cookiePolicy}, in particular when you use our website and content that we make available for you to download (for example, when you register to download software, eBooks, whitepapers), or other online services that we offer to you. For example, we collect information about what content you download from our website and what content you have clicked on or viewed and how.

FOR WHAT PURPOSES WE USE YOUR PERSONAL DATA

We only will use your personal data for the following purposes ("Permitted Purposes"):

Part of the data is collected to ensure error-free provision of the website. Other data may be used to analyse your user behaviour.

Management of the login data for your individualised user account, in which you can use certain contents and services on our website. Deposit of your e-mail address so that we can send you new access data in case you should forget it.

Maintain and protect the security of our products, services and websites or other systems, prevent and detect security risks, fraud or other criminal or unlawful activity.

General compliance purposes, in particular to ensure compliance with our legal and regulatory obligations and requirements, such as compliance screenings or record-keeping requirements (e.g. due to competition law, export law, trade sanctions and embargo provisions or to prevent economic crime or money laundering). This may include the following measures: Matching your contact or identity information against relevant sanctions lists and confirming your identity in case of a possible match, recording your cooperation with third parties to the extent that it may be of antitrust relevance, reporting to or review by relevant regulatory, law enforcement or other competent authorities.

The legal bases for the processing of your personal data are set out in Article 6 GDPR. Depending on which of the permissible purposes described above we process your personal data for, the processing is carried out either for the performance of a contract or other business agreement with SWISS KRONO or to comply with our legal obligations or to protect the legitimate interests of SWISS KRONO or third parties, always provided that your interests or fundamental rights and freedoms do not suspend and conflict with a processing. In addition, processing may be carried out on the basis of your express consent, insofar as you have given us this.

HOW DO WE COLLECT YOUR DATA?

On the one hand, your data is collected by you share with us. This can be data that you enter into a contact form, for example.

Other data is automatically collected by our IT systems when you visit the website. This is mainly technical data (e.g. internet browser, operating system or time of the site access). This data is collected automatically as soon as you enter our website.

WHAT RIGHTS DO YOU HAVE REGARDING YOUR DATA?

You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to demand the correction, blocking or deletion of this data. You also have the right to have data that we process automatically on the basis of your consent or in fulfilment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible. For this purpose as well as for further questions on the subject of data protection, you can contact us at any time at the address given in the imprint. In the event of violations of data protection law, the person concerned has the right to submit a complaint with the competent supervisory authority.

ANALYSIS TOOLS AND THIRD-PARTY TOOLS

When visiting our website, your surfing behaviour can be statistically evaluated. This happens mainly with cookies and with so-called analysis programs. The analysis of your surfing behaviour is usually anonymous; the surfing behaviour cannot be traced back to you. You can object to this analysis or prevent it by not using certain tools. You can find detailed information on this in the following part of Privacy Policy.

DATA SECURITY

For security reasons and to protect the transmission of confidential content, such as the creation of a project list or requests that you send to us as the site operator, this site uses SSL or TLS encryption. You can recognise an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.

<div id="cookie-policy" class="inline-block">If</div> SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.

COOKIES

The internet pages partly use so-called cookies. Cookies do not cause any damage to your computer and do not contain viruses. Cookies serve to make our offer more user-friendly, effective and more secure. Cookies are small text files that are stored on your computer and saved by your browser.

Most of the cookies we use are so-called "session cookies". They are automatically deleted after the end of your visit. Other cookies remain stored on your terminal device until you delete them. These cookies enable us to recognise your browser on your next visit.

You can set your browser such that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or generally and activate the automatic deletion of cookies when closing the browser. When cookies will be deactivated, the functionality of this website may be limited.

Cookies that are required to carry out the electronic communication process or to provide certain functions desired by you (e.g. shopping cart function), are stored on the basis of Art. 6 (1) lit. f GDPR. The website operator has a legitimate interest in the storage of cookies for the technically error-free and optimized provision of the operator’s services. Insofar as other cookies (e.g. cookies to analyse your surfing behaviour) are stored, these will be treated separately in this Privacy Policy.

SERVER LOG FILES

The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us.

These are:
•    Browser type and browser version
•    operating system used
•    referrer URL
•    Host name of the accessing computer
•    Time of the server request
•    IP address

This data is not merged with other data sources.

The basis for data processing is Art. 6 para. 1 lit. b GDPR, which permits the processing of data for the fulfilment of a contract or pre-contractual measures.

CONTACT FORM

If you send us enquiries by the contact form, you will be passed to the website ${swisskronoLinkEn} and must accept the privacy policy of the relevant responsible party (SWISS KRONO Tex GmbH & Co KG) before sending your message. The information you provide us in the enquiry form, including the contact data you enter there, will be stored by the responsible party for the purpose of processing the enquiry and in case of follow-up questions. This data will not be passed on without your consent.

The processing of the data entered in the contact form is therefore based exclusively on your consent (Art. 6 para. 1 lit. a GDPR). You can revoke this consent at any time. For this purpose, an informal communication by e-mail to the responsible person (${responsibleMail}) is sufficient. The legality of the data processing operations carried out until the revocation remains unaffected by the revocation.

The data entered by you in the contact form will remain with the responsible party until you request its deletion, revoke your consent to its storage or the purpose for storing the data no longer applies (e.g. after processing your request has been completed). Mandatory legal provisions - in particular retention periods - remain unaffected.

REGISTRATION ON THE WEBSITE

You can register on our website to use additional features on the site. We manage the data entered for this purpose with the help of Google Firebase. We use this data only for the purpose of using the respective offer or service for which you have registered. The mandatory information requested during registration must be provided in full. Otherwise we will reject the registration.

For important changes, for example in the scope of the offer or in the case of technically necessary changes, we use the e-mail address provided during registration to inform you in that way.

The processing of the data entered during registration is based on your consent (Art. 6 para. 1 lit. a GDPR). You can revoke your consent at any time. For this purpose, a formless communication by e-mail to us is sufficient. The legality of the data processing already carried out remains unaffected by the revocation.

The data collected during registration will be stored by us for as long as you are registered on our website and will then be deleted. Legal retention periods remain unaffected.

You can find more information about how Google Firebase handles user data in Google's privacy statement:

${googlePrivacyLink}

PROCESSING OF DATA (CUSTOMER AND CONTRACTUAL DATA)

We collect, process and use personal data only to the extent that they are necessary for the establishment, content or modification of the legal relationship (inventory data). This is done on the basis of Art. 6 para. 1 lit. b GDPR, which permits the processing of data for the performance of a contract or pre-contractual measures. We collect, process and use personal data about the use of our internet pages (usage data) only insofar as this is necessary to enable the user to use the service.

The collected customer data will be deleted after completion of the order or termination of the business relationship. Statutory retention periods remain unaffected.

GOOGLE ANALYTICS

This website uses functions of the web analysis service Google Analytics. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.

Google Analytics makes use so-called "cookies". These are text files that are stored on your computer and which allow an analysis of your using the website. The information generated by the cookies regarding using of this website are typically transmitted to and stored on Google’s server in the USA.

The Google Analytics cookies shall be stored in accordance with GDPR, Art. 6, para. 1 (f). The website operator has a legitimate interest in the analysis of user behaviour in order to optimise both its web offerings and marketing materials.

IP anonymization

We activated the IP anonymization function on this website. This means that your IP address is abbreviated by Google within member states of the European Union or in other signatory states to the Agreement on the European Economic Area before being transmitted to the USA. In exceptional cases only, the entire IP address shall be transmitted to a Google server in the USA and abbreviated there. On behalf of the operator of this website, Google shall use this information to analyse your usage of the website, to draft reports about the website activities and to perform other services relating to the website usage and the usage of the Internet for the website operator. The IP address transmitted within the context of Google Analytics shall not be combined with any other data by Google.

browser plugin

You may refuse the storage of cookies by adjusting the settings of your browser software accordingly; however please note that if you do that, in that case you may not be able to use the full functionality of this website. In addition, you may prevent the recording of data generated by the cookies and associated to your usage of the website (including your IP address) by Google and the processing of this data by Google in that you downloaded and install the browser plugin available at the following link:

${browserPluginLinkEn}

For further information on how Google Analytics handles user data, please see Google's privacy policy:

${googleAnalyticsLinkEn}

Order data

We signed a contract with Google for the processing of order data and fully implement the strict provisions of the German Data Protection Authorities when using Google Analytics.

Demographic characteristics in Google Analytics

This website uses Google Analytics “demographic characteristics” function. This allows for reports comprising information on age, gender and interests of the website visitors. Above data originates from Google’s interest-related advertisement as well as from the visitor’s data provided by third parties. Above data cannot be associated with a specific person. You can deactivate this function at any time adjusting the display settings in your Google account or generally prohibit the recording of your data by Google Analytics as described under the header “Objecting to the recording of data.”

WHERE PERSONAL DATA ARE PROCESSED

In the course of providing the features of our website ${timberplannerLink.long_link} free of charge, we may also transfer your personal data to recipients in countries outside the European Economic Area ("third party countries") that do not have the same level of data protection as your home country. Where we do so, we comply with applicable data protection requirements and take appropriate security measures to ensure that your personal data is protected and secure, in particular by agreeing to EU standard contractual clauses, which you can access here:

${eurlexEuropaLinkEn}

If you would like getting more information about these security measures, please contact us at any time using the contact details below.

HOW WE PROTECT YOUR PERSONAL DATA

In order to protect your personal data, we ensure our physical, electronic and procedural security measures that comply with the current state of technology and legal data protection requirements. These security measures include the implementation of specific technologies and processes to protect your privacy, such as secure servers, firewalls, and SSL encryption. We will at all times act in accordance with applicable laws and regulations relating to the confidentiality and security of personal data.

WITH WHOM WE SHARE YOUR PERSONAL DATA

We may share your personal information as follows:

with our associated companies within the SWISS KRONO Group around the world, if and to the extent necessary for the permissible purposes described above and permitted by law. In such cases, these companies will only use the personal data for the same permissible purposes and under the same conditions as above.

to service providers (called processors) within or outside the SWISS KRONO Group in Switzerland or abroad (e.g. shared service centers or cloud providers) who have been commissioned by us to process personal data on our behalf and exclusively in accordance with our instructions for the permitted purposes. In this case, we retain control about and responsibility for your personal data and will take appropriate protective measures required under applicable law to ensure the integrity and security of your personal data whenever such service providers are commissioned.

with courts, law enforcement agencies or other relevant authorities or lawyers to the extent permitted by law and necessary to comply with a legal obligation or to establish, assert or defend legal claims.

with credit agencies and other companies in the context of credit decisions, for the prevention of fraud and for the collection of receivables.

in all other cases, we will only disclose your personal data if you instruct us to do so or give your consent, if we are required to do so by law or by a court or governmental order, or if we suspect fraudulent or criminal offence.

HOW LONG DO WE STORE YOUR PERSONAL DATA

We will retain your personal data for as long as necessary to provide the services or products requested or information requested and to conduct and manage our business relationship with you. If you have asked us not to contact you, we will retain that information for as long as necessary to comply with that request. Furthermore, we are legally obliged to retain certain types of personal data for certain periods of time (e.g. due to commercial law or tax law retention obligations). Your personal data will be deleted immediately when it is no longer required for these purposes.

OBLIGATION TO PROVIDE PERSONAL DATA

In general, you provide us with your personal data on a voluntary basis. In principle, it has no adverse consequences for you if you do not consent or do not provide your personal data. However, there are cases in which we cannot act without your personal data, e.g. if this personal data is necessary to process your orders, to provide you with access to an online offer or newsletter, or to carry out a legally required compliance check. In these cases, we are unfortunately unable to fulfil your request without the relevant personal data.

IF I AM UNDER 16 YEARS OLD

If you are under 16 years of age, you will need the consent of your parent/legal guardian before providing us with your personal data. Persons under the age of 16 are not permitted to provide us with their personal data without such consent.

CHANGES TO THIS PRIVACY INFORMATION

This data protection information was last updated in July 2019. We may amend or update this information if this becomes necessary due to changes in the way we process data or in the legal requirements. Therefore we recommend that you check from time to time or when you provide us with personal data to see if there have been any changes. Changes to the data protection information apply from the date on which they were published on our website.

HOW YOU CAN CONTACT US

If you have any questions or comments, or would like to assert your rights, please contact:

SWISS KRONO Tec AG
Museggstrasse 14
6004 Lucerne
Switzerland
Email: ${dataProtectionMail}
`,
      },
    };
  },
};
</script>


<style lang="scss" scoped>
</style>
